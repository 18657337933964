<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2 align-items-center">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :text="avatarText(localOptions.name)"
          :variant="`light-primary`"
          size="50px"
          rounded
        />
      </template>
      <h4 class="m-0">
        {{ localOptions.name }}
      </h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="localOptions.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="localOptions.email"
              type="email"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Field: Client -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Client"
            label-for="client"
          >
            <v-select
              v-model="localOptions.client_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clientOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="client"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="4"
          md="4"
        >
          <!-- Field: CPV Profile -->
          <b-form-group
            label="Cpv profile"
            label-for="cpv-profile"
          >
            <v-select
              id="cpv-profile"
              v-model="localOptions.cpv_profiles"
              :options="cpvProfilesOptions"
              :reduce="val => val.id"
              label="name"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Field: Status -->
        <b-col
          cols="4"
          md="4"
        >
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <b-form-checkbox
              v-model="localOptions.status"
              switch
              inline
              value="1"
              unchecked-value="0"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="4"
          md="4"
        >
          <!-- Role -->
          <b-form-group
            label="Role"
            label-for="role"
          >
            <v-select
              v-model="localOptions.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rolesOptions"
              :clearable="false"
              :reduce="option => option.value"
              input-id="role"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>

    <!-- Action Buttons -->
    <div class="mt-2">
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="onSubmit"
      >
        Save Changes
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="resetForm"
      >
        Reset
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const localOptions = JSON.parse(JSON.stringify(this.userData))
    delete localOptions.roles
    localOptions.role = this.userData && this.userData.roles && this.userData.roles.length > 0 ? this.userData.roles[0].name : null

    return {
      localOptions,
      clientOptions: [],
      cpvProfilesOptions: [],
    }
  },
  created() {
    store.dispatch('clients/fetchClientsAll')
      .then(response => {
        this.clientOptions = response.data.map(client => ({
          label: client.name,
          value: client.id,
        }))
      })
      .catch(error => {
        console.log(error)
      })

    store.dispatch('tenders/fetchAllProfiles')
      .then(response => {
        this.cpvProfilesOptions = response.data
      })
  },
  setup() {
    const statusOptions = [
      {
        label: 'Active',
        value: 1,
      },
      {
        label: 'Inactive',
        value: 0,
      },
    ]

    const rolesOptions = [
      {
        label: 'Admin',
        value: 'Admin',
      },
      {
        label: 'TenderViewer',
        value: 'TenderViewer',
      },
      {
        label: 'TestUser',
        value: 'TestUser',
      },
      {
        label: 'TenderSearcher',
        value: 'TenderSearcher',
      },
      {
        label: 'TenderNotifier',
        value: 'TenderNotifier',
      },
    ]

    return {
      avatarText,
      statusOptions,
      rolesOptions,
    }
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.userData))
    },
    onSubmit() {
      store.dispatch('user/updateUser', this.localOptions)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating user',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
